export default class EngineCommon {
    //如果采用引擎作为页面，那么判断是否有自己定义事件接口；如果业务自己有页面，则判断业务模块是否有定义该方法
    static existMethod(dataObj:any,methodName:string):boolean{
        let eventName=dataObj.otherParams.compParams.modelPath+'_'+methodName;//事件名称要加上模块路径作为前缀
        if(dataObj.otherParams.compParams.isEnginePage){//如果采用引擎作为页面
            return window.customEvent.indexOf(eventName)>-1?true:false;//判断是否有自己定义事件
        }else{
            return dataObj.modelCompRef[methodName]?true:false;//判断业务模块是否自己有定义该方法
        }
    }
    //执行业务模块的方法或者触发自定义事件
    static async execMethod(engineInst:any,methodName:string,params:any={}):Promise<any>{
        params.engineInst=engineInst;
        if(engineInst.otherParams.compParams.isEnginePage){
            let eventName=engineInst.otherParams.compParams.modelPath+'_'+methodName;//事件名称
            //如果有自定义该事件，则开始调用触发方法触发自定义事件
            if(window.customEvent.indexOf(eventName)>-1)return await engineInst.utilInst.utils.Event.dispatchEvent(eventName,params);
        }else{
            return await engineInst.modelCompRef[methodName](...Object.values(params));//调用业务模块的该方法
        }
    }
    //调用业务组件引用（如果采用的是引擎作为页面，那么业务组件引用其实就是引擎本身proxy）
    static getModelCompRef(engineInst:any):any{
        return engineInst.otherParams.compParams.isEnginePage?engineInst:engineInst.modelCompRef;
    }
    //调用业务组件的compParams（如果采用的是引擎作为页面，那么注意业务组件引用其实就是引擎本身proxy）
    static getCompParams(engineInst:any):any{
        return engineInst.otherParams.compParams.isEnginePage?engineInst.otherParams.compParams:engineInst.modelCompRef.compParams;
    }
}