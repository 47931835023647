import BaseBean from "@/utils/BaseBean";
import invalid from '../../../assets/flow/invalid.png';
import makebill from '../../../assets/flow/makebill.png';
import inflow from '../../../assets/flow/inflow.png';
import rollback from '../../../assets/flow/rollback.png';
import finish from '../../../assets/flow/finish.png';
import {ICardEngineDataObj} from "@/components/base_comp/cardEngine/cardEngineUtil";
export let submitData:ISubmitData={
    id:'',//单据id
    defineId:'',//流程定义id
    billModel:'',//单据类型
    curTask:'',//当前流程步骤
    submitTask:'',//要提交到哪一步的流程步骤
    assignUserId:'',//提交流程的时候，选择的人员
    stepData:'',//提交或者驳回的时候，输入的描述信息
    isSaveSubmit:false//是否保存提交
}
export interface IBillEngineDataObj {
    utilInst:BillEngineUtil
    engineUtil:any
    refMap:Map<string,any>
    approveLog:boolean//审批日志抽屉显示/影藏控制字段
    reverse:boolean//日志排序方式，默认降序排列
    loading:boolean//整个单据引擎的遮罩层是否显示
    submitDialogVisible:boolean//提交弹出框是否显示
    submitDialogLoading:boolean//提交弹出框的遮罩层
    rollbackDialogVisible:boolean//回滚弹出框是否显示
    rollbackDialogLoading:boolean//回滚弹出框的遮罩层
    activeName:string//单据底部的tab默认选中审批日志tab
    mainMaxHeight:number//默认内容的最大高度，实际上在mounted中会动态计算出来
    approveLogContentHeight:number//审批意见默认高度，为了出现滚动条，需要动态计算出审批意见的最大高度
    logInfoArr:Array<any>//审批日志
    billInfo:any//单据信息，展示单据的时候会调用addOrLoad方法，该方法会从后台查询信息赋值给它
    submitData:ISubmitData//提交、驳回、受理、取消受理都会向后台发送请求，请求的时候都会带上该对象作为参数
    buttons:Array<any>//单据头部按钮
    modelCompRef: any
    formRef:any//表单ref
    form:any
    modelComp:any//本次引擎将包含的组件(外界传入的组件)
    otherParams:any
}
export default class BillEngineUtil extends BaseBean{
    public dataObj:IBillEngineDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IBillEngineDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //提取向后台发送请求
    public async doRequest(options:any):Promise<void>{
        //构造提交请求路径
        let url = this.dataObj.modelCompRef.compParams.modelPath + options.operType;
        //发起提交请求并得到请求结果
        let res = await this.utils.Api.postRequest({url: url, params: options.params?options.params:this.dataObj.submitData});
        if(res.result){
            await this.dataObj.engineUtil.doAddOrLoad(this.dataObj.modelCompRef.form.id,'bill')
            this.utils.Tools.success();
            if(options.fn)options.fn(res);
        }
    }
    //初始化引擎中某些参数
    public initDataObjParams(dialogInst:any):void{
        //1-不管是否采用引擎作为的页面，先把列表传过来的参数赋值一份给otherParams.compParams。
        //需要注意：如果要采用引擎作为页面，那么列表传入参数到引擎的时候，需要指定isEnginePage来覆盖这里的值；而且还要指定compParams参数，
        //compParams参数的结构必须满足自定义业务模块传给引擎的参数一致，比如卡片modelPath，hasDetails等等
        if(this.props.engineParams.compParams)Object.assign(this.dataObj.otherParams.compParams,this.props.engineParams.compParams);
        //2-经过上面之后，就可以知道到底是否采用引擎作为页面了。如果采用引擎作为页面，那么引擎包含的compParams参数也确定了，下方处理的是如果不采用引擎作为页面的时候，
        //怎么让引擎获得具体业务模块的compParams。经过这样的处理之后，引擎中的otherParams.compParams就含有所有的参数了，
        //以后不管是否是采用引擎作为页面，都操作otherParams.compParams即可。
        if(!this.dataObj.otherParams.compParams.isEnginePage)Object.assign(this.dataObj.otherParams.compParams,this.dataObj.modelCompRef.compParams);//业务模块的参数
        //3-如果采用引擎作为页面，那么把form表单挂到proxy身上，这样就和业务模块表单挂在业务模块的proxy一样了，可以减少代码
        if(this.props.engineParams.compParams && this.props.engineParams.compParams.form)Object.assign(this.dataObj.form,this.props.engineParams.compParams.form);

        //-50：底部makeInfo高度50px; -20-20标识：el-main向上下padding：20px；-40:title；-40按钮区域的高度；-10:dialog的body向内padding了10；-23：底部：23
        this.dataObj.mainMaxHeight=(window.innerHeight-50-20-20-40-40-10-23);//计算业务单据区域最大高度
        this.dataObj.approveLogContentHeight=(window.innerHeight-171);//审批意见的高度，设置了高度，那么超过高度就会有滚动条
        this.dataObj.modelCompRef.engine=this.proxy;//为业务单据模块添加单据引擎对象，方便在单据模块处使用
        this.dataObj.modelCompRef.dialogInst=dialogInst;//为业务模块添加弹出框对象，方便在模块处使用
    }
    //初始化流程步骤
    public initFlowStep():void{
        switch (this.dataObj.otherParams.billStatus) {
            case -1:this.dataObj.otherParams.flowStatus.backgroundImage='url('+invalid+')';break;
            case 0:this.dataObj.otherParams.flowStatus.backgroundImage='url('+makebill+')';break;
            case 1:this.dataObj.otherParams.flowStatus.backgroundImage='url('+inflow+')';break;
            case 2:this.dataObj.otherParams.flowStatus.backgroundImage='url('+rollback+')';break;
            case 3:this.dataObj.otherParams.flowStatus.backgroundImage='url('+finish+')';break;
        }
    }
    //选择审批步骤变化事件
    public doTaskChange(val:any):void{
        //1、清空其它步骤所选择的审批人 2、让选择审批人下拉框可以使用
        this.dataObj.billInfo.taskUsers.forEach((item:any)=>{//循环变量所有步骤
            if(item.task.taskId!=val){//如果遍历步骤和当前已选步骤不等，则清空它已选审批人和把审批人下拉框置为不可用
                item.selUser='';item.disabled=true;
            }else{//找到已选步骤，让其下拉框可用
                if(item.chooseOperator==0)item.disabled=false;//如果选中的步骤允许选择经办人，则把disabled改成false，否则还是禁用状态
            }
        });
    }
    //保存并提交
    public async doSaveSubmit(engineParams:any,dialogInst:any):Promise<void>{
        //如果卡片的form表单合法则发起保存请求方法
        await this.dataObj.modelCompRef.formRef.validate(async (valid:BufferSource) =>{
            if(valid){
                let result:boolean=await this.dataObj.engineUtil.doSave(engineParams,'bill',dialogInst,false);
                if(result)this.dataObj.submitDialogVisible=true;//打开提交弹出框
            }
        });
    }
    //提交弹出框确认按钮事件
    public async submitDialogSure():Promise<void>{
        //如果提交的时候有选择指派人员，则以下的result就不为空
        let selTask=this.dataObj.billInfo.taskUsers.find((item:any)=>{
            return item.selUser!='';
        });

        //如果有选择提交步骤
        if(this.utils.UtilPub.isNotEmpty(this.dataObj.submitData.submitTask)){
            //如果有选择指派人，则把指派人放到提交参数里面去
            if(selTask && this.utils.UtilPub.isNotEmpty(selTask.selUser)){
                this.dataObj.submitData.assignUserId=selTask.selUser.toString();
            }
            //如果选择提交的步骤不是流程结束，那么需要检查选择的步骤是否有审核人
            if(this.dataObj.submitData.submitTask!='end'){
                let item=this.dataObj.billInfo.taskUsers.find((item:any)=>item.task.taskId==this.dataObj.submitData.submitTask);
                if(item.users.length==0){
                    this.utils.Tools.warning({message:"所选步骤没有审核人"});
                    return ;
                }
            }
            //提交之前接口，供业务模块使用
            if (this.dataObj.modelCompRef['beforeSubmit'] && !await this.dataObj.modelCompRef['beforeSubmit'](this.proxy)) return;
            await this.doSubmit();
            this.dataObj.submitDialogVisible=false;//关闭提交弹出框
        }else{//如果没有选择提交步骤
            this.proxy.$message('请选择提交步骤')
        }
    }
    //提交
    public async doSubmit():Promise<void>{
        this.dataObj.submitDialogLoading=true;
        await this.doRequest({operType:'/submit',fn:(res:any)=>{
                this.dataObj.submitDialogLoading=false;
                //如果业务模块有定义提交之后事件，则会调用
                if (this.dataObj.modelCompRef['afterSubmit']) this.dataObj.modelCompRef['afterSubmit'](res,this.proxy);
            }});
    }
    //受理
    public doClaim():void{
        this.utils.Tools.configBox({
            message:'你将受理该单据, 是否继续?',
            sureFn: async()=>{
                //如果业务模块有定义受理之前事件，则会调用
                if (this.dataObj.modelCompRef['beforeClaim'] && ! await this.dataObj.modelCompRef['beforeClaim'](this.proxy))return;
                await this.doRequest({operType:'/claim',fn:(res:any)=>{
                        //如果业务模块有定义受理之后事件，则会调用
                        if (this.dataObj.modelCompRef['afterClaim']) this.dataObj.modelCompRef['afterClaim'](res,this.proxy);
                    }});
            }
        });
    }
    //取消受理
    public doCancelClaim():void{
        this.utils.Tools.configBox({
            message:'你将取消受理该单据, 是否继续?',
            sureFn:async ()=>{
                //如果业务模块有定义取消受理受理之前事件，则会调用
                if (this.dataObj.modelCompRef['beforeCancelClaim'] && ! await this.dataObj.modelCompRef['beforeCancelClaim'](this.proxy))return;
                await this.doRequest({operType:'/cancelClaim',fn:(result:any)=>{
                        //如果业务模块有定义取消受理之后事件，则会调用
                        if (this.dataObj.modelCompRef['afterCancelClaim']) this.dataObj.modelCompRef['afterCancelClaim'](result,this.proxy);
                    }});
            }
        });
    }
    //驳回
    public async doRollback():Promise<void>{
        //如果业务模块有定义驳回之前事件，则会调用
        if (this.dataObj.modelCompRef['beforeRollBack'] && ! await this.dataObj.modelCompRef['beforeRollBack'](this.proxy))return;
        await this.doRequest({operType:'/rollBack',fn:(res:any)=>{
                this.dataObj.rollbackDialogVisible=false;
                //如果业务模块有定义驳回之后事件，则会调用
                if (this.dataObj.modelCompRef['afterRollBack']) this.dataObj.modelCompRef['afterRollBack'](res,this.proxy);
            }});
    }
    //取回
    public fetchBack():void{
        this.utils.Tools.configBox({
            message:'你确定要取回该该单据吗?',
            sureFn:async ()=>{
                //如果业务模块有定义取回单据之前事件，则会调用
                if (this.dataObj.modelCompRef['beforeFetchBack'] && ! await this.dataObj.modelCompRef['beforeFetchBack'](this.proxy))return;
                await this.doRequest({operType:'/fetchBack',fn:(result:any)=>{
                        //如果业务模块有定义取回单据之后事件，则会调用
                        if (this.dataObj.modelCompRef['afterFetchBack']) this.dataObj.modelCompRef['afterFetchBack'](result,this.proxy);
                    }});
            }
        });
    }
    //会签
    public async doSign():Promise<void>{
        this.dataObj.otherParams.sign.dialogLoading=true;
        if (this.dataObj.modelCompRef['beforeSign'] && ! await this.dataObj.modelCompRef['beforeSign'](this.proxy))return;
        await this.doRequest({operType:'/sign',params:Object.assign(this.dataObj.submitData,this.dataObj.otherParams.sign.form),fn:(res:any)=>{
                this.dataObj.otherParams.sign.visible=false;
                this.dataObj.otherParams.sign.dialogLoading=false;
                //如果业务模块有定义会签之后事件，则会调用
                if (this.dataObj.modelCompRef['afterSign']) this.dataObj.modelCompRef['afterSign'](res,this.proxy);
            }});
    }
    //作废
    public doInvalid():void{
        this.utils.Tools.configBox({
            message:'你确定要作废该单据吗?',
            sureFn:async ()=>{
                //如果业务模块有定义作废单据之前事件，则会调用
                if (this.dataObj.modelCompRef['beforeInvalid'] && ! await this.dataObj.modelCompRef['beforeInvalid'](this.proxy))return;
                await this.doRequest({operType:'/invalid',fn:(result:any)=>{
                        //如果业务模块有定义作废单据之后事件，则会调用
                        if (this.dataObj.modelCompRef['afterInvalid']) this.dataObj.modelCompRef['afterInvalid'](result,this.proxy);
                    }});
            }
        });
    }
}